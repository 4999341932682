
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@100;200;300;500;600&family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500&family=Roboto:ital,wght@1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Serif+Georgian:wght@100;200;300;500;600&family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500&family=Roboto:ital,wght@1,100&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Zen+Loop:ital@0;1&display=swap');

@font-face {
  font-family: Drunk;
  src: url(./fonts/Druk\ Wide\ Cy\ Bold\ Regular.ttf);
}

@font-face {
  font-family: SF Pro Text;
  src: url(./fonts/82579dd027fa9b5c81840bc079533860.ttf);
}

@font-face {
  font-family: SF Pro Text Regular;
  src: url(./fonts/FontsFree-Net-SFProText-Regular\ \(1\).ttf);
}

input[type="text"]::-moz-placeholder {
  color: #14213d; /* Цвет подсказывающего текста */
}
input[type="text"]::-webkit-input-placeholder {
  color: #14213d;
}
input[type="text"]:-ms-input-placeholder {
  color: #14213d;
}
input[type="text"]::-ms-input-placeholder {
  color: #14213d;
}
input[type="text"]::placeholder {
  color: #14213d;
}
input[type="text"] {
  color: #14213d;
}
input[type="number"] {
  color: #14213d;
}
textarea {
  color: #14213d;
}
.swiper-pagination {
  display: flex; /* Display bullets as a flex container */
  justify-content: center; /* Center align bullets horizontally */
  align-items: center; /* Center align bullets vertically */
  margin-bottom: 5px;
}

.swiper-pagination .swiper-pagination-bullet {
  margin-top: 300px;
  width: 6px; /* Set width of each bullet */
  height: 6px; /* Set height of each bullet */
  background-color: #f08523; /* Set background color of bullets */
  border-radius: 50%; /* Add border-radius for rounded bullets */
  cursor: pointer; /* Add pointer cursor for clickable bullets */
}



span {
  background: transparent;
}
.formBooking{
  display: flex;
}

@media (max-width : 768px) {
  form{
    display: block;
  }
  select{
    font-size: 24px;
  }
  .formBooking{
    display: block;
  }

}


@media (max-width : 400px) {
  form{
    display: block;
  }

  select{
    font-size: 15px;
    width: 230px;
  }
  .formBooking{
    display: block;
  }

}

.active:active{
  color: #5551F8;
}
.active:checked{
  color: #5551F8;
  background-color: #f08523;
}
