*{
    background-color: #FFFFFF;
}

html, body{
    scroll-behavior: smooth;
}

a, a:hover{
    text-decoration: none;
}